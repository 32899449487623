import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMeetingrooms(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/meeting-rooms', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchMeetingroom(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/meeting-rooms/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updateMeetingroom(ctx, { id, formData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/meeting-rooms/${id}`, formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addMeetingroom(ctx, meetingroomData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/meeting-rooms', meetingroomData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    deleteMeetingroom(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/meeting-rooms/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
  },
}
