import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchHeadquarters(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/headquarters', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchHeadquarter(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/headquarters/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateHeadquarter(ctx, { id, formData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/headquarters/${id}`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    activate(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/headquarters/activate/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deactivate(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/headquarters/deactivate/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addHeadquarter(ctx, headquarterData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/headquarters', headquarterData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteHeadquarter(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/headquarters/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
